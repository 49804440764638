import React, { useState } from 'react';

const DietCalculator = () => {
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [pal, setPal] = useState('1.6');
  const [dietPlan, setDietPlan] = useState(null);

  const calculateBMR = (weight, height, age, gender) => {
    if (gender === 'male') {
      return 10 * weight + 6.25 * height - 5 * age + 5;
    } else {

      return 10 * weight + 6.25 * height - 5 * age - 161;
    }
  };

  const calculateBMI = (weight, height) => {
    return (weight / ((height / 100) ** 2)).toFixed(1);
  };

  const baseItems = [
    { name: 'Sponsor Isolate 94', amount: 30, unit: 'g', caloriesPerUnit: 4, proteinPerUnit: 0.9, carbsPerUnit: 0, fatPerUnit: 0.01, fiberPerUnit: 0 },
    { name: 'Magermilch', amount: 350, unit: 'ml', caloriesPerUnit: 0.35, proteinPerUnit: 0.035, carbsPerUnit: 0.049, fatPerUnit: 0.0017, fiberPerUnit: 0 },
    { name: 'Kakao Nibs', amount: 5, unit: 'g', caloriesPerUnit: 6, proteinPerUnit: 0.1, carbsPerUnit: 0.66, fatPerUnit: 0.34, fiberPerUnit: 0.34 },
    { name: 'Gojibeeren', amount: 10, unit: 'g', caloriesPerUnit: 3.4, proteinPerUnit: 0.07, carbsPerUnit: 0.6, fatPerUnit: 0, fiberPerUnit: 0.2 },
    { name: 'Bienenpollen', amount: 5, unit: 'g', caloriesPerUnit: 3, proteinPerUnit: 0.14, carbsPerUnit: 0.46, fatPerUnit: 0.06, fiberPerUnit: 0.14 },
    { name: 'Haferkleie', amount: 10, unit: 'g', caloriesPerUnit: 4, proteinPerUnit: 0.3, carbsPerUnit: 0.7, fatPerUnit: 0.1, fiberPerUnit: 0.5 },
  ];

  const calculateDietPlan = () => {
    const currentWeight = parseFloat(weight);
    const currentHeight = parseFloat(height);
    const currentBMI = calculateBMI(currentWeight, currentHeight);
    let targetWeight = currentWeight;
    if (currentBMI > 24.9) {
      targetWeight = (24.9 * (currentHeight / 100) ** 2).toFixed(1);
    }
    
    const bmr = calculateBMR(currentWeight, currentHeight, age, gender);
    const tdee = bmr * parseFloat(pal);
    let dietCalories = tdee;
    let calorieDeficitFromDiet = 0;
    const calorieDeficitFromExercise = 300; // Assuming 300 kcal from exercise
    
    if (currentBMI > 24.9) {
      calorieDeficitFromDiet = 700; // Create a deficit only if BMI is above 24.9
      dietCalories = tdee - calorieDeficitFromDiet + calorieDeficitFromExercise;
    }

    const totalCalorieDeficit = calorieDeficitFromDiet + calorieDeficitFromExercise;
    const weeklyWeightLoss = (totalCalorieDeficit * 7) / 7000; // 7000 kcal ≈ 1 kg of fat

    const baseShakeCalories = baseItems.reduce((sum, item) => sum + item.amount * item.caloriesPerUnit, 0);
    const targetShakeCalories = dietCalories * 0.7; // Targeting 70% of diet calories from shakes
    const calorieAdjustmentFactor = targetShakeCalories / (baseShakeCalories * 3);

    const adjustedItems = baseItems.map(item => ({
      ...item,
      amount: Math.round(item.amount * calorieAdjustmentFactor * 10) / 10
    }));

    const actualShakeCalories = adjustedItems.reduce((sum, item) => sum + item.amount * item.caloriesPerUnit, 0) * 3;
    const wholeFoodCalories = dietCalories - actualShakeCalories;
    const wholeFoodPerMeal = wholeFoodCalories / 2;

    setDietPlan({
      currentWeight,
      currentBMI,
      targetWeight,
      targetBMI: calculateBMI(targetWeight, currentHeight),
      bmr: Math.round(bmr),
      tdee: Math.round(tdee),
      totalCalories: Math.round(dietCalories),
      shakesCalories: Math.round(actualShakeCalories),
      wholeFoodCalories: Math.round(wholeFoodCalories),
      wholeFoodPerMeal: Math.round(wholeFoodPerMeal),
      calorieDeficitFromDiet,
      calorieDeficitFromExercise,
      totalCalorieDeficit,
      weeklyWeightLoss,
      adjustedItems,
      breakfastItems: adjustedItems,
      lunchItems: adjustedItems,
      dinnerItems: adjustedItems.map(item => item.name === 'Sponsor Isolate 94' ? {...item, name: 'Casein Protein Isolate'} : item)
    });
  };

  const renderMealTable = (mealName, mealItems, includeWholeFoodSupplement = false) => {
    if (!dietPlan) return null;

    return (
      <div key={mealName} className="meal-table">
        <h3>{mealName}</h3>
        <table>
          <thead>
            <tr>
              <th>Lebensmittel</th>
              <th>Menge</th>
              <th>Kalorien</th>
              <th>Protein</th>
              <th>Kohlenhydrate</th>
              <th>Fett</th>
              <th>Ballaststoffe</th>
            </tr>
          </thead>
          <tbody>
            {mealItems.map((item, index) => {
              const calories = item.amount * item.caloriesPerUnit;
              const protein = item.amount * item.proteinPerUnit;
              const carbs = item.amount * item.carbsPerUnit;
              const fat = item.amount * item.fatPerUnit;
              const fiber = item.amount * item.fiberPerUnit;

              return (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.amount.toFixed(1)}{item.unit}</td>
                  <td>{calories.toFixed(1)}</td>
                  <td>{protein.toFixed(1)}g</td>
                  <td>{carbs.toFixed(1)}g</td>
                  <td>{fat.toFixed(1)}g</td>
                  <td>{fiber.toFixed(1)}g</td>
                </tr>
              );
            })}
            <tr className="total-row">
              <td>Summe</td>
              <td></td>
              <td>{mealItems.reduce((sum, item) => sum + item.amount * item.caloriesPerUnit, 0).toFixed(1)}</td>
              <td>{mealItems.reduce((sum, item) => sum + item.amount * item.proteinPerUnit, 0).toFixed(1)}g</td>
              <td>{mealItems.reduce((sum, item) => sum + item.amount * item.carbsPerUnit, 0).toFixed(1)}g</td>
              <td>{mealItems.reduce((sum, item) => sum + item.amount * item.fatPerUnit, 0).toFixed(1)}g</td>
              <td>{mealItems.reduce((sum, item) => sum + item.amount * item.fiberPerUnit, 0).toFixed(1)}g</td>
            </tr>
            {includeWholeFoodSupplement && (
              <tr className="supplement-row">
                <td colSpan="2">*Whole Food Ergänzung</td>
                <td>{dietPlan.wholeFoodPerMeal.toFixed(1)}</td>
                <td colSpan="4"></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="diet-calculator">
    <h1>Individuell angepasste Ernährungsempfehlung</h1>
    <div className="input-section">
      <div className="input-group">
        <label htmlFor="age">Alter</label>
        <input id="age" type="number" placeholder="Alter" value={age} onChange={(e) => setAge(e.target.value)} />
      </div>
      <div className="input-group">
        <label htmlFor="gender">Geschlecht</label>
        <select id="gender" value={gender} onChange={(e) => setGender(e.target.value)}>
          <option value="">Auswählen</option>
          <option value="male">Männlich</option>
          <option value="female">Weiblich</option>
        </select>
      </div>
      <div className="input-group">
        <label htmlFor="height">Größe (cm)</label>
        <input id="height" type="number" placeholder="Größe" value={height} onChange={(e) => setHeight(e.target.value)} />
      </div>
      <div className="input-group">
        <label htmlFor="weight">Gewicht (kg)</label>
        <input id="weight" type="number" placeholder="Aktuelles Gewicht" value={weight} onChange={(e) => setWeight(e.target.value)} />
      </div>
      <div className="input-group">
        <label htmlFor="pal">Aktivitätslevel</label>
        <select id="pal" value={pal} onChange={(e) => setPal(e.target.value)}>
          <option value="1.2">PAL 1.2 - Sehr niedrige Aktivität</option>
          <option value="1.4">PAL 1.4 - Niedrige Aktivität</option>
          <option value="1.6">PAL 1.6 - Mäßige Aktivität</option>
          <option value="1.8">PAL 1.8 - Aktive Lebensweise</option>
          <option value="2.0">PAL 2.0 - Sehr aktive Lebensweise</option>
          <option value="2.2">PAL 2.2 - Extrem aktive Lebensweise</option>
        </select>
      </div>
      <button onClick={calculateDietPlan}>Diätplan berechnen</button>
    </div>

      {dietPlan && (
        <div className="results-section">
          <h2>Ihr personalisierter Diätplan</h2>
          <div className="summary-grid">
            <div className="summary-item">
              <span className="label">Aktuelles Gewicht:</span>
              <span className="value">{dietPlan.currentWeight} kg (BMI: {dietPlan.currentBMI})</span>
            </div>
            <div className="summary-item">
              <span className="label">Zielgewicht:</span>
              <span className="value">{dietPlan.targetWeight} kg (BMI: {dietPlan.targetBMI})</span>
            </div>
            <div className="summary-item">
              <span className="label">Grundumsatz (BMR):</span>
              <span className="value">{dietPlan.bmr} kcal</span>
            </div>
            <div className="summary-item">
              <span className="label">Gesamter Tagesenergiebedarf (TDEE):</span>
              <span className="value">{dietPlan.tdee} kcal</span>
            </div>
            <div className="summary-item">
              <span className="label">Tägliche Kalorien für die Diät:</span>
              <span className="value">{dietPlan.totalCalories} kcal</span>
            </div>
            <div className="summary-item">
              <span className="label">Kalorien aus Shakes:</span>
              <span className="value">{dietPlan.shakesCalories} kcal</span>
            </div>
            <div className="summary-item">
              <span className="label">Kalorien aus Vollwertkost:</span>
              <span className="value">{dietPlan.wholeFoodCalories} kcal</span>
            </div>
            <div className="summary-item highlight">
              <span className="label">Gesamtkaloriendefizit:</span>
              <span className="value">{dietPlan.totalCalorieDeficit} kcal</span>
            </div>
            <div className="summary-item">
              <span className="label">- davon durch Ernährung:</span>
              <span className="value">{dietPlan.calorieDeficitFromDiet} kcal</span>
            </div>
            <div className="summary-item">
              <span className="label">- davon durch Sport:</span>
              <span className="value">{dietPlan.calorieDeficitFromExercise} kcal</span>
            </div>
            <div className="summary-item highlight">
              <span className="label">Geschätzte wöchentliche Gewichtsabnahme:</span>
              <span className="value">{dietPlan.weeklyWeightLoss.toFixed(2)} kg</span>
            </div>
          </div>

          {renderMealTable('Frühstück', dietPlan.breakfastItems)}
          {renderMealTable('Mittagessen', dietPlan.lunchItems, true)}
          {renderMealTable('Abendessen', dietPlan.dinnerItems, true)}
        </div>
      )}
      
      <style jsx>{`
        .diet-calculator {
          font-family: 'Arial', sans-serif;
          
          margin: 0 auto;
          padding: 20px;
          background-color: #f8f9fa;
          border-radius: 8px;
        }

        h1 {
          color: #343a40;
          text-align: center;
          margin-bottom: 30px;
          font-size: 24px;
        }

        .input-section {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }

        .input-group {
          display: flex;
          flex-direction: column;
        }

        label {
          margin-bottom: 5px;
          font-weight: bold;
          color: #495057;
        }

        input, select {
          width: 100%;
          padding: 10px;
          border: 1px solid #ced4da;
          border-radius: 4px;
          font-size: 16px;
          background-color: white;
        }

        input::placeholder {
          color: #adb5bd;
        }

        select {
          appearance: none;
          background-image: url('data:image/svg+xml;charset=US-ASCII,<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L7 7L13 1" stroke="%23495057" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
          background-repeat: no-repeat;
          background-position: right 10px center;
          padding-right: 30px;
        }

        button {
          width: 100%;
          padding: 12px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          font-size: 16px;
          cursor: pointer;
          transition: background-color 0.3s ease;
          margin-top: 10px;
        }

        button:hover {
          background-color: #0056b3;
        }

     

        .results-section {
          background-color: white;
          padding: 20px;
          border-radius: 5px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .summary-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          gap: 15px;
          margin-bottom: 30px;
        }

        .summary-item {
          background-color: #ecf0f1;
          padding: 10px;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
        }

        .summary-item.highlight {
          background-color: #e74c3c;
          color: white;
        }

        .label {
          font-weight: bold;
          margin-bottom: 5px;
        }

        .meal-table {
          margin-bottom: 30px;
        }

        table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 10px;
        }

        th, td {
          border: 1px solid #bdc3c7;
          padding: 8px;
          text-align: left;
        }

        th {
          background-color: #34495e;
          color: white;
        }

        .total-row {
          font-weight: bold;
          background-color: #ecf0f1;
        }

        .supplement-row {
          font-style: italic;
          background-color: #f9e79f;
        }
      `}</style>
    </div>
  );
};

export default DietCalculator;